import 'core-js/stable';
import 'regenerator-runtime/runtime';
import './vendor/modernizr';
import React from 'react';
import ReactDOM from 'react-dom';
import { PageLoader } from 'commonBlocks/components/pageLoader';
import { init as initSentry } from '@sentry/browser';
import { isIE } from 'react-device-detect'; 
import { appConfig, environment } from './config';


/*if (isIE) {
  import(
    /* webpackChunkName: "browserNotSupported" 
    './pageBrowserNotSupported'
  ).then(module => {
    const PageBrowserNotSupportedView = module.default;

    ReactDOM.render(
      <PageBrowserNotSupportedView />,
      document.getElementById('appRoot')
    );
  });
} else */ {
  ReactDOM.render(<PageLoader />, document.getElementById('appRoot'));

  // Set up error handling with Sentry if needed options are specified
  if (appConfig.sentry) {
    initSentry({
      dsn: appConfig.sentry.dsn,
      release: appConfig.sentry.release,
      environment,
    });
  } 

  import(
    /* webpackChunkName: "primary" */
    './initialize'
  );
}
