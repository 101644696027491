import { IAPIConfig } from './globals';

export const appConfig: IAPIConfig = window.__APP_CONFIG__;

const envsByHostname: Record<
  string,
  'production' | 'staging' | 'testing' | 'demo'
> = {
  'secure.paxport.net': 'production',
  'staging.paxport.se': 'staging',
  'test.paxport.se': 'testing',
  'demo.paxport.se': 'demo',
};

export const environment = envsByHostname[window.location.hostname] || 'local';
