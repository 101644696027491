import React from 'react';
import PlaneSVG from './PlaneSVG';
import fallback from './fallback.gif';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
};

export function PageLoader(props): JSX.Element {
  // Since IE and Edge browsers don't support SVG SMIL animation
  // we provide a .gif fallback
  if (!Modernizr.smil) {
    return (
      <img
        src={fallback}
        alt="Loading indicator"
        width="140"
        style={style}
        {...props}
      />
    );
  }

  return <PlaneSVG style={style} {...props} />;
}
