import React from 'react';

const SvgLoadingIndicator = props => (
  <svg width={104} height={79} color="#7367E9" {...props}>
    <defs>
      <linearGradient
        x1="1%"
        y1="50%"
        x2="0%"
        y2="50%"
        id="loading_indicator_svg__b"
      >
        <animate
          attributeName="x1"
          values="1%; 100%;"
          dur="1.5s"
          repeatCount="indefinite"
        />
        <animate
          attributeName="x2"
          values="0%; 99%;"
          dur="1.5s"
          repeatCount="indefinite"
        />
        <stop stopColor="currentColor" stopOpacity={0} offset="0%" />
        <stop stopColor="currentColor" offset="100%" />
      </linearGradient>
      <path
        id="loading_indicator_svg__a"
        d="M97.23 37.42a8.73 8.73 0 0 1-2.3 2.36c-.38.26-1.3.83-3.9 1.52-.54.14-2.22.57-3.93.78-2.96.36-10.95.61-21.6.49-1.2 1.16-2.4 2.31-3.6 3.47h4.5v7.45c-3.96-.07-7.92-.13-11.88-.19L32.11 74.81h-9.25l22.47-32.62-31.47-.9-7.19 5.91-6.17.26 7.32-10.02L.5 27.42l6.17.26 7.19 5.9 31.47-.9L22.86.05h9.25c7.47 7.17 14.94 14.34 22.41 21.52 3.96-.06 7.92-.13 11.88-.19v7.45h-4.5c1.2 1.16 2.4 2.31 3.6 3.47 10.65-.12 18.63.13 21.6.49 1.71.21 3.39.64 3.93.78 2.6.68 3.52 1.26 3.9 1.52 1.15.76 1.87 1.68 2.3 2.33z"
      />
    </defs>
    <g fill="none">
      <path
        fill="#404040"
        d="M104 35.26c-.52.67-1.25 1.46-2.25 2.2-2.19 1.61-4.44 2.03-5.65 2.17-.75.04-1.5.09-2.25.13a528.54 528.54 0 0 1-71.21-.9h-.18l-7.03 5.5-6.03.24 4.4-5.74 2.76-3.59-7.16-9.32 6.03.24 7.03 5.5 30.78-.84L31.26.46h9.05c7.31 6.68 14.61 13.36 21.92 20.03l11.62-.18v6.94h-4.4c1.17 1.08 2.35 2.15 3.52 3.23 10.42-.11 18.23.13 21.13.45 1.67.19 3.32.6 3.84.73 2.54.63 3.44 1.17 3.81 1.41 1.12.73 1.83 1.59 2.25 2.19z"
      />
      <g transform="translate(0 4)">
        <use fill="#FFF" xlinkHref="#loading_indicator_svg__a" />
        <use
          fill="url(#loading_indicator_svg__b)"
          xlinkHref="#loading_indicator_svg__a"
        />
      </g>
      <g fill="#D3D3D3">
        <path
          opacity={0.15}
          d="M5.68 31.67l-4.08-.18 9.06 8.11H46.3l-1.96-2.92-31.47.89-7.19-5.9"
        />
        <path
          opacity={0.18}
          d="M2.37 31.53l8.64 7.54h34.93l-1.6-2.39-31.47.89-7.19-5.9z"
        />
        <path
          opacity={0.22}
          d="M3.28 31.56l8.08 6.94h34.21l-1.23-1.82-31.47.89-7.19-5.9z"
        />
        <path
          opacity={0.22}
          d="M4.11 31.6l7.61 6.55h33.61l-.99-1.47-31.47.89-7.19-5.9z"
        />
        <path
          d="M27.13 4.05h3.98l22.41 21.52 7.38 7.26 3.6 3.47c2.71-.01 6.79-.01 11.81-.01 2.68 0 5.75.02 9.78.49 3.98.47 7.23 1.19 9.43 3.73.32.37.55.69.7.92-11.99-.41-23.98-.81-35.96-1.22C49.22 28.15 38.17 16.1 27.13 4.05z"
          opacity={0.1}
        />
        <path
          d="M28.22 4.05L60.97 39.6H94.6a6.114 6.114 0 0 0-2.3-1.34c-2.31-.78-3.91-1.1-3.91-1.1s-1.51-.3-2.78-.45c-2.48-.3-10.4-.5-21.11-.42C53.37 25.54 42.24 14.8 31.11 4.05h-2.89z"
          opacity={0.14}
        />
        <path
          d="M28.67 4.05l33.01 35.02h32.23a10.6 10.6 0 0 0-1.71-.87c-.52-.21-.94-.32-1.73-.55-.65-.18-1.19-.31-1.56-.4-.51-.12-1.25-.27-2.15-.4-1.34-.2-2.34-.25-3.28-.29-2.58-.12-3.87-.18-5.03-.21-3.11-.08-5.5-.09-6.08-.1-2.08-.01-4.75-.01-7.87.03C53.37 25.54 42.24 14.8 31.11 4.05h-2.44z"
          opacity={0.17}
        />
        <path
          d="M31.11 4.05h-1.67l32.82 34.1H92c-.63-.24-1.63-.6-2.89-.92-2.56-.65-4.6-.73-5.79-.78-3.09-.11-9.86-.23-18.81-.17C53.37 25.54 42.24 14.8 31.11 4.05z"
          opacity={0.2}
        />
        <path
          d="M26.17 4.05l33.44 36.74h36.16c-.2-.28-.51-.68-.96-1.08-.67-.6-1.44-1.06-2.91-1.59-1.75-.63-3.18-.91-3.68-1-2.42-.44-4.33-.54-5.07-.58-5.14-.25-8.47-.25-8.47-.25-3.91-.01-7.34 0-10.16.01C53.37 25.54 42.24 14.8 31.11 4.05h-4.94z"
          opacity={0.08}
        />
      </g>
      <g fill="#D3D3D3">
        <path opacity={0.12} d="M53.52 25.56l11.89-.19v3.98l-7.81.22z" />
        <path opacity={0.15} d="M53.52 25.56l11.89-.19v3.42h-8.6z" />
        <path opacity={0.15} d="M56.39 28.38h9.02v-3.01l-11.89.19z" />
        <path opacity={0.18} d="M53.52 25.56l11.89-.19v2.7h-9.34z" />
        <path opacity={0.22} d="M53.52 25.56l11.89-.19v2.1h-9.94z" />
      </g>
      <g fill="#D3D3D3">
        <path
          d="M60.91 50.03h4.5v3.81c-2.8.01-5.6.02-8.4.04 1.29-1.29 2.6-2.57 3.9-3.85z"
          opacity={0.12}
        />
        <path opacity={0.15} d="M60.91 50.03h4.5v3.28h-7.82z" />
        <path opacity={0.15} d="M60.91 50.03h4.5v2.81h-7.35z" />
        <path opacity={0.22} d="M60.91 50.03h4.5v2.39h-6.93z" />
        <path d="M64.5 46.56L31.11 78.8 64.5 46.56z" opacity={0.12} />
      </g>
      <path
        fill="#D3D3D3"
        opacity={0.03}
        d="M61.2 49.74h-5.49L28.22 78.8h2.89z"
      />
      <path
        fill="#D3D3D3"
        opacity={0.05}
        d="M28.73 78.8h2.38L61.2 49.74h-4.79z"
      />
      <path
        fill="#D3D3D3"
        opacity={0.04}
        d="M29.15 78.8L57 49.74h4.2L31.11 78.8z"
      />
      <path
        fill="#D3D3D3"
        opacity={0.02}
        d="M29.67 78.8l27.92-29.06h3.61L31.11 78.8z"
      />
      <path
        fill="#E0E0E0"
        opacity={0.02}
        d="M30.08 78.8l27.98-29.06h3.14L31.11 78.8z"
      />
    </g>
  </svg>
);

export default SvgLoadingIndicator;
